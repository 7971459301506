// ** Icon imports
import HomeIcon from 'mdi-material-ui/Home'
import PersonIcon from '@mui/icons-material/Person'
import CommuteIcon from '@mui/icons-material/Commute'
import SavingsIcon from '@mui/icons-material/Savings'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import CampaignIcon from '@mui/icons-material/Campaign'
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PeopleIcon from '@mui/icons-material/People'
import TaskIcon from '@mui/icons-material/Task'
import LockIcon from '@mui/icons-material/Lock'
import ArticleIcon from '@mui/icons-material/Article'
import HelpIcon from '@mui/icons-material/Help'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditNoteIcon from '@mui/icons-material/EditNote'
import User from '@mui/icons-material/ManageAccounts'
import SosIcon from '@mui/icons-material/Sos'
const navigation = () => {
  return [
    {
      title: 'Dashboard',
      icon: HomeIcon,
      path: '/dashboard'
    },
    // {
    //   sectionTitle: 'Website Settings'
    // },
    // {
    //   title: 'Website',
    //   icon: PublicIcon,
    //   children: [
    //     {
    //       title: 'Privacy Policy',
    //       icon: LockIcon,
    //       path: '/privacy-policy'
    //     },
    //     {
    //       title: 'Terms and Conditions',
    //       icon: TaskIcon,
    //       path: '/terms-and-conditions'
    //     },
    //     {
    //       title: 'Testimonials',
    //       icon: FormatQuoteIcon,
    //       path: '/testimonials'
    //     },
    //     {
    //       title: 'Contact Inquiry',
    //       icon: ContactMailIcon,
    //       path: '/contact'
    //     },
    //     {
    //       title: 'FAQ',
    //       icon: HelpIcon,
    //       path: '/faq'
    //     },

    //     {
    //       title: 'Blog',
    //       icon: ArticleIcon,
    //       path: '/blog'
    //     },
    //     {
    //       title: 'Job Categories',
    //       icon: CategoryIcon,
    //       children: [
    //         {
    //           title: 'Job Post Categories',
    //           icon: CategoryIcon,
    //           path: '/career-categories'
    //         },
    //         {
    //           title: 'Job Post Section',
    //           icon: ReduceCapacityIcon,
    //           path: '/career-section'
    //         },
    //       ]
    //     },
    //     {
    //       title: 'Social Media',
    //       icon: ConnectWithoutContactIcon,
    //       path: '/social-media'
    //     }
    //   ]
    // },
    // {
    //   sectionTitle: 'Knowledge Base'
    // },
    // {
    //   title: 'Users',
    //   icon: PeopleIcon,
    //   children: [
    //     {
    //       title: 'Driver',
    //       icon: PersonIcon,
    //       path: '/driver'
    //     },
    //     {
    //       title: 'Rider',
    //       icon: PersonIcon,
    //       path: '/user'
    //     }
    //   ]
    // },
    // {
    //   title: 'User Management',
    //   icon: PersonIcon,
    //   path: '/user'
    // },
    // {
    //   title: 'Driver Management',
    //   icon: PersonIcon,
    //   path: '/driver'
    // },
    {
      title: 'User Management',
      icon: PeopleIcon,
      children: [
        {
          title: 'Patient',
          icon: PersonIcon,
          path: '/patient'
        },
        {
          title: 'Driver',
          icon: PersonIcon,
          path: '/driver'
        }
      ]
    },

    {
      title: 'Roles Management',
      icon: User,
      children: [
        {
          title: 'Permissions',
          icon: User,
          path: '/permissions'
        },
        {
          title: 'Roles',
          icon: User,
          path: '/roles'
        }
        // {
        //   title: 'User',
        //   icon: User,
        //   path: '/user'
        // }
      ]
    },

    {
      title: 'Fleet/Vehicle Management',
      icon: TimeToLeaveIcon,
      children: [
        {
          title: 'Vehicle Type',
          icon: CommuteIcon,
          path: '/vehicle-type'
        }
        // {
        //   title: 'Vehicle Category',
        //   icon: CommuteIcon,
        //   path: '/vehicle-categories'
        // }
      ]
    },
    // {
    //   title: 'Fare Management',
    //   icon: CorporateFareIcon,
    //   path: '/fare'
    // },

    // {
    //   title: 'Pincode',
    //   icon: DialpadIcon,
    //   path: '/pincode'
    // },
    {
      title: 'Support Management',
      icon: ContactSupportIcon,
      path: '/support'
    },
    {
      title: 'Complaints Management',
      icon: EditNoteIcon,
      path: '/complaints'
    },
    {
      title: 'SOS Requests',
      icon: SosIcon,
      path: '/sos'
    },
    // {
    //   title: 'Language Management',
    //   icon: TranslateIcon,
    //   path: '/language'
    // },
    // {
    //   title: 'Rides',
    //   icon: GarageIcon,
    //   path: '/rides'
    // },
    // {
    //   title: 'Rental Rides',
    //   icon: GarageIcon,
    //   path: '/rental-rides'
    // },
    // {
    //   title: 'Notification',
    //   icon: NotificationsActiveRoundedIcon,
    //   path: '/notifications'
    // },
    {
      title: 'Referral Management',
      icon: CampaignIcon,
      path: '/referral'
    },
    // {
    //   title: 'Maintenance Management',
    //   icon: ManageAccountsIcon,
    //   path: '/maintenance'
    // },
    // {
    //   title: 'Reviews and Ratings',
    //   icon: StarRoundedIcon,
    //   path: '/review'
    // },
    {
      title: 'Service Location Management',
      icon: LocationCityIcon,
      path: '/service-location'
    },
    // {
    //   title: 'City Management',
    //   icon: LocationCityIcon,
    //   path: '/city'
    // },
    // {
    //   title: 'Price Management',
    //   icon: SavingsIcon,
    //   path: '/price'
    // },
    {
      title: 'Trips Management',
      icon: CommuteIcon,
      children: [
        {
          title: 'Trips Management',
          icon: CommuteIcon,
          path: '/trips'
        },
        {
          title: "God's View",
          icon: RemoveRedEyeIcon,
          path: '/gods-view'
        }
      ]
    },
    // {
    //   title: 'Schedule Ride Management',
    //   icon: CalendarMonthIcon,
    //   path: '/schedule'
    // },

    {
      title: 'Content Management',
      icon: ArticleIcon,
      children: [
        {
          title: 'Privacy Policy',
          icon: LockIcon,
          path: '/privacy-policy'
        },
        {
          title: 'Terms and Conditions',
          icon: TaskIcon,
          path: '/terms-and-conditions'
        },
        {
          title: 'FAQ',
          icon: HelpIcon,
          path: '/faq'
        }
      ]
    }
    // {
    //   title: 'Privacy Policy',
    //   icon: LockIcon,
    //   path: '/privacy-policy'
    // },
    // {
    //   title: 'Terms and Conditions',
    //   icon: TaskIcon,
    //   path: '/terms-and-conditions'
    // }
  ]
}

export default navigation
