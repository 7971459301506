import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'
import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'

//website routes
const PrivacyPolicyPage = React.lazy(() => import('./pages/website-settings/privacy-policy'))
const TermsandConditionPage = React.lazy(() => import('./pages/website-settings/terms-and-condition'))
const ContactPage = React.lazy(() => import('./pages/website-settings/contact'))
const TestimonialsPage = React.lazy(() => import('./pages/website-settings/testimonials'))
const FaqPage = React.lazy(() => import('./pages/website-settings/faq'))
const CareerPage = React.lazy(() => import('./pages/website-settings/career-section'))
const CareerCategoriesPage = React.lazy(() => import('./pages/website-settings/career-section/career-categories'))
const BlogPage = React.lazy(() => import('./pages/website-settings/blog'))
const SocialMediaPage = React.lazy(() => import('./pages/website-settings/social-media'))

const HomePage = React.lazy(() => import('./pages/home'))
const DashboardPage = React.lazy(() => import('./pages/dashboard'))
const LoginPage = React.lazy(() => import('./pages/login'))
const ForgotPassword = React.lazy(() => import('./pages/login/forgotpassword'))
const ChangePassword = React.lazy(() => import('./pages/change-password'))
const DriverPage = React.lazy(() => import('./pages/driver'))
const DriverDetailPage = React.lazy(() => import('./pages/driver/DriverDetail'))
const PatientDetailPage = React.lazy(() => import('./pages/customer/patientDetails'))
const DriverHistoryPage = React.lazy(() => import('./pages/driver/driverHistory'))
const DriverHistoryDetailsPage = React.lazy(() => import('./pages/driver/DriverHistoryDetails'))
const RecentTransactionPage = React.lazy(() => import('./pages/driver/recentTransaction'))
const CustomerPage = React.lazy(() => import('./pages/customer'))
const RideHistoryPage = React.lazy(() => import('./pages/customer/ridesHistory'))
const RidesmapPage = React.lazy(() => import('./pages/customer/ridesmap'))
const RecentTransactionsPage = React.lazy(() => import('./pages/customer/recentTransaction'))

const PincodePage = React.lazy(() => import('./pages/pincode'))
const DocumentPage = React.lazy(() => import('./pages/supports'))
const LanguagePage = React.lazy(() => import('./pages/language'))
const NotificationPage = React.lazy(() => import('./pages/notification'))
const VehicleTypePage = React.lazy(() => import('./pages/vehicle-type'))
const FarePage = React.lazy(() => import('./pages/fare'))
const PriceManagementPage = React.lazy(() => import('./pages/price-management'))
const CityPage = React.lazy(() => import('./pages/city'))
const ServiceLocation = React.lazy(() => import('./pages/service-locations'))
const RidesPage = React.lazy(() => import('./pages/rides'))
const RidesDetailpage = React.lazy(() => import('./pages/rides/ridesDetailpage'))
const SosPage = React.lazy(() => import('./pages/sos'))
const RentalRidesPage = React.lazy(() => import('./pages/rental-rides'))
const ReviewPage = React.lazy(() => import('./pages/review'))
const ReferFriendPage = React.lazy(() => import('./pages/referral'))
const VehicleCategoriesPage = React.lazy(() => import('./pages/vehicle-type/vehicle-categories'))
const FeedBackPage = React.lazy(() => import('./pages/feedback'))
const FeedBackCategory = React.lazy(() => import('./pages/feedback/feedback-category'))
const CouponsPage = React.lazy(() => import('./pages/trips'))
const CashoutRequestPage = React.lazy(() => import('./pages/schedule'))
const MaintenancePage = React.lazy(() => import('./pages/maintenance'))
const GodsViewPage = React.lazy(() => import('./pages/gods-view'))
const ComplaintsPage = React.lazy(() => import('./pages/complaints'))
const UserPermissionPage = React.lazy(() => import('./pages/user-permission'))
const AddUserPermissionPage = React.lazy(() => import('./pages/user-permission/add-user-permission'))
const EditUserPermissionPage = React.lazy(() => import('./pages/user-permission/edit-user-permission'))
const UserRolesPage = React.lazy(() => import('./pages/user-roles'))
const AddUserRolePage = React.lazy(() => import('./pages/user-roles/add-user-role'))
const EditUserRolePage = React.lazy(() => import('./pages/user-roles/edit-user-role'))
const UserPage = React.lazy(() => import('./pages/user'))
const UserDetailPage = React.lazy(() => import('./pages/user/user-detail'))
const AddUserPage = React.lazy(() => import('./pages/user/add-user'))
const EditUserPage = React.lazy(() => import('./pages/user/edit-user'))
const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />
            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <UserLayout>
                {' '}
                <Outlet />{' '}
              </UserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path='' element={<HomePage />} />
              <Route path='/dashboard' element={<DashboardPage />} />
              <Route path='/change-password' element={<ChangePassword />} />
              <Route path='/driver' element={<DriverPage />} />
              <Route path='/driver/:driverId' element={<DriverDetailPage />} />
              <Route path='/drivers/:driverId' element={<DriverHistoryPage />} />
              <Route path='/driver/wallets/:driverId' element={<RecentTransactionPage />} />
              <Route path='/driver/history/:driverId/details' element={<DriverHistoryDetailsPage />} />

              <Route path='/patient' element={<CustomerPage />} />
              <Route path='/user/:rideId' element={<RideHistoryPage />} />
              <Route path='/patient/:id' element={<PatientDetailPage />} />
              <Route path='/user/history/:CustomerId' element={<RidesmapPage />} />
              <Route path='/user/wallets/:rideId' element={<RecentTransactionsPage />} />

              <Route path='/pincode' element={<PincodePage />} />
              <Route path='/complaints' element={<ComplaintsPage />} />
              <Route path='/support' element={<DocumentPage />} />
              <Route path='/notification' element={<NotificationPage />} />
              <Route path='/language' element={<LanguagePage />} />
              <Route path='/vehicle-type' element={<VehicleTypePage />} />
              <Route path='/fare' element={<FarePage />} />
              <Route path='/price' element={<PriceManagementPage />} />
              <Route path='/city' element={<CityPage />} />
              <Route path='/service-location' element={<ServiceLocation />} />
              <Route path='/rides' element={<RidesPage />} />
              <Route path='/rides/:ridesId' element={<RidesDetailpage />} />

              <Route path='/permissions' element={<UserPermissionPage />} />
              <Route path='/permissions/add-permissions' element={<AddUserPermissionPage />} />
              <Route path='/permissions/edit-permission/:id' element={<EditUserPermissionPage />} />

              <Route path='/roles' element={<UserRolesPage />} />
              <Route path='/roles/add-role' element={<AddUserRolePage />} />
              <Route path='/roles/edit-role/:id' element={<EditUserRolePage />} />

              {/* <Route path='/user' element={<UserPage />} />
              <Route path='/user/add-user' element={<AddUserPage />} />
              <Route path='/user/:id' element={<UserDetailPage />} />
              <Route path='/user/edit-user/:id' element={<EditUserPage />} /> */}

              <Route path='/sos' element={<SosPage />} />
              <Route path='/rental-rides' element={<RentalRidesPage />} />
              <Route path='/review' element={<ReviewPage />} />
              <Route path='/notifications' element={<NotificationPage />} />
              <Route path='/referral' element={<ReferFriendPage />} />
              <Route path='/vehicle-categories' element={<VehicleCategoriesPage />} />
              <Route path='/feedback' element={<FeedBackPage />} />
              <Route path='/feedback-category' element={<FeedBackCategory />} />
              <Route path='/trips' element={<CouponsPage />} />
              <Route path='/schedule' element={<CashoutRequestPage />} />
              <Route path='/maintenance' element={<MaintenancePage />} />
              {/* website */}
              <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
              <Route path='/terms-and-conditions' element={<TermsandConditionPage />} />
              <Route path='/testimonials' element={<TestimonialsPage />} />
              <Route path='/faq' element={<FaqPage />} />
              <Route path='/contact' element={<ContactPage />} />
              <Route path='/career-section' element={<CareerPage />} />
              <Route path='/career-categories' element={<CareerCategoriesPage />} />
              <Route path='/blog' element={<BlogPage />} />
              <Route path='/social-media' element={<SocialMediaPage />} />
              <Route path='/gods-view' element={<GodsViewPage />} />
            </Route>
          </Route>
          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />
        </Routes>
      </AclGuard>
    </Suspense>
  )
}

export default App
