export const API_BASE_URL = 'https://api.veloxy.care'
// export const API_BASE_URL = 'http://192.168.29.54:4000'
// export const API_BASE_URL = 'http://103.204.189.64:5004'
export const MEDIA_URL = 'https://dx9hhbu8rta5s.cloudfront.net'

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/v1/admin/auth/login`,
    me: `${API_BASE_URL}/api/v1/admin/auth/me`,
    forgot: `${API_BASE_URL}/api/v1/admin/auth/forgot/password`,
    verifotp: `${API_BASE_URL}/api/v1/admin/auth/verify/otp`,
    reset: `${API_BASE_URL}/api/v1/admin/auth/reset/password`,
    change: `${API_BASE_URL}/api/v1/admin/auth/change/password`
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/admin/user/counts`
  },
  USERS: {
    list: type => `${API_BASE_URL}/api/v1/admin/user/list/${type}`,
    listbyrides: id => `${API_BASE_URL}/api/v1/admin/rides/customer/history/${id}`,
    listbydriver: id => `${API_BASE_URL}/api/v1/admin/rides/driver/history/${id}`,
    map: id => `${API_BASE_URL}/api/v1/admin/rides/${id}`,
    edit: id => `${API_BASE_URL}/api/v1/admin/user/${id}`,
    create: `${API_BASE_URL}/api/v1/admin/user`,
    listbyid: id => `${API_BASE_URL}/api/v1/admin/user/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/user/${id}`,
    drivercategory: id => `${API_BASE_URL}/api/v1/admin/user/vehicle/${id}`,
    driverbyid: id => `${API_BASE_URL}/api/v1/admin/rides/${id}`,
    paymentHistory: id => `${API_BASE_URL}/api/v1/admin/user/payment/history/${id}`,
    walletadd: id => `${API_BASE_URL}/api/v1/admin/wallets/transfer/${id}`,
    DeductWallet: id => `${API_BASE_URL}/api/v1/admin/wallets/deduction/${id}`,
    wallet: id => `${API_BASE_URL}/api/v1/admin/wallets/${id}`
  },
  EXPORTSFILES: {
    csv: type => `${API_BASE_URL}/api/v1/admin/exports/csv/${type}`,
    excel: type => `${API_BASE_URL}/api/v1/admin/exports/excel/${type}`
  },
  DRIVER: {
    edit: id => `${API_BASE_URL}/api/v1/admin/user/vehicle/${id}`
  },
  PINCODE: {
    list: `${API_BASE_URL}/api/v1/admin/pincodes`,
    create: `${API_BASE_URL}/api/v1/admin/pincodes`,
    edit: id => `${API_BASE_URL}/api/v1/admin/pincodes/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/pincodes/${id}`
  },
  PRICE_MANAGEMENT: {
    list: `${API_BASE_URL}/api/v1/admin/prices`,
    create: `${API_BASE_URL}/api/v1/admin/prices`,
    edit: id => `${API_BASE_URL}/api/v1/admin/prices/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/prices/${id}`,
    listbyid: id => `${API_BASE_URL}/api/v1/admin/prices/${id}`
  },
  CITY: {
    list: `${API_BASE_URL}/api/v1/admin/city`,
    create: `${API_BASE_URL}/api/v1/admin/city`,
    edit: id => `${API_BASE_URL}/api/v1/admin/city/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/city/${id}`
  },
  LOCATION: {
    list: `${API_BASE_URL}/api/v1/admin/service/locations`,
    create: `${API_BASE_URL}/api/v1/admin/service/locations`,
    edit: id => `${API_BASE_URL}/api/v1/admin/service/locations/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/service/locations/${id}`
  },
  COUNTRY: {
    list: `${API_BASE_URL}/api/v1/misc/countries`
  },
  RIDES: {
    list: `${API_BASE_URL}/api/v1/admin/rides`,
    edit: id => `${API_BASE_URL}/api/v1/admin/rides/${id}`
  },
  CONTACT: {
    list: `${API_BASE_URL}/api/v1/admin/contact_us`,
    reply: id => `${API_BASE_URL}/api/v1/admin/contact_us/${id}`
  },
  DOCUMENT: {
    list: `${API_BASE_URL}/api/v1/admin/supports`,
    create: `${API_BASE_URL}/api/v1/admin/supports`,
    edit: id => `${API_BASE_URL}/api/v1/admin/supports/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/supports/${id}`
  },
  VEHICLE_TYPE: {
    list: `${API_BASE_URL}/api/v1/admin/vehicle/types`,
    create: `${API_BASE_URL}/api/v1/admin/vehicle/types`,
    edit: id => `${API_BASE_URL}/api/v1/admin/vehicle/types/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/vehicle/types/${id}`
  },
  VEHICLE_CATEGORIES: {
    list: `${API_BASE_URL}/api/v1/admin/categories/vehicle`,
    create: `${API_BASE_URL}/api/v1/admin/categories/vehicle`,
    edit: id => `${API_BASE_URL}/api/v1/admin/categories/vehicle/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/categories/vehicle/${id}`
  },
  FAQ: {
    list: `${API_BASE_URL}/api/v1/admin/faqs`,
    create: `${API_BASE_URL}/api/v1/admin/faqs`,
    edit: id => `${API_BASE_URL}/api/v1/admin/faqs/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/faqs/${id}`
  },
  LANGUAGE: {
    list: `${API_BASE_URL}/api/v1/admin/languages`,
    create: `${API_BASE_URL}/api/v1/admin/languages`,
    edit: id => `${API_BASE_URL}/api/v1/admin/languages/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/languages/${id}`
  },
  PRIVACY_POLICY: {
    list: `${API_BASE_URL}/api/v1/admin/legalcontent/privacy_policy`,
    edit: `${API_BASE_URL}/api/v1/admin/legalcontent/privacy_policy`
  },
  Terms_AND_CONDITIONS: {
    list: `${API_BASE_URL}/api/v1/admin/legalcontent/terms_and_conditions`,
    edit: `${API_BASE_URL}/api/v1/admin/legalcontent/terms_and_conditions`
  },
  Testimonials: {
    list: `${API_BASE_URL}/api/v1/admin/testimonials`,
    create: `${API_BASE_URL}/api/v1/admin/testimonials`,
    edit: id => `${API_BASE_URL}/api/v1/admin/testimonials/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/testimonials/${id}`
  },
  GET_REGION: {
    country: `https://api.countrystatecity.in/v1/countries`,
    state: `https://api.countrystatecity.in/v1/countries/`,
    city: `https://api.countrystatecity.in/v1/countries/`
  },
  NOTIFICATION: {
    list: `${API_BASE_URL}/api/v1/admin/notifications`,
    edit: id => `${API_BASE_URL}/api/v1/admin/notifications/${id}`
  },
  REFER_FRIEND: {
    list: `${API_BASE_URL}/api/v1/admin/refer_friend`,
    edit: type => `${API_BASE_URL}/api/v1/admin/refer_friend/${type}`
  },
  CAREER_CATEGORY: {
    list: `${API_BASE_URL}/api/v1/admin/categories/career`,
    create: `${API_BASE_URL}/api/v1/admin/categories/career`,
    edit: id => `${API_BASE_URL}/api/v1/admin/categories/career/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/categories/career/${id}`
  },
  CAREER_SECTION: {
    list: `${API_BASE_URL}/api/v1/admin/careers`,
    create: `${API_BASE_URL}/api/v1/admin/careers`,
    edit: id => `${API_BASE_URL}/api/v1/admin/careers/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/careers/${id}`
  },
  BLOG: {
    list: `${API_BASE_URL}/api/v1/admin/blogs`,
    create: `${API_BASE_URL}/api/v1/admin/blogs`,
    edit: id => `${API_BASE_URL}/api/v1/admin/blogs/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/blogs/${id}`
  },
  FEEDBACK_CATEGORY: {
    list: `${API_BASE_URL}/api/v1/admin/categories/feedback`,
    create: `${API_BASE_URL}/api/v1/admin/categories/feedback`,
    edit: id => `${API_BASE_URL}/api/v1/admin/categories/feedback/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/categories/feedback/${id}`
  },
  FEEDBACK: {
    list: `${API_BASE_URL}/api/v1/admin/feedbacks`,
    create: `${API_BASE_URL}/api/v1/admin/feedbacks`,
    edit: id => `${API_BASE_URL}/api/v1/admin/feedbacks/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/feedbacks/${id}`
  },
  FOOTER: {
    list: `${API_BASE_URL}/api/v1/admin/categories/footer`,
    create: `${API_BASE_URL}/api/v1/admin/categories/footer`,
    edit: id => `${API_BASE_URL}/api/v1/admin/categories/footer/${id}`
  },
  COUPONS: {
    list: `${API_BASE_URL}/api/v1/admin/trips`,
    create: `${API_BASE_URL}/api/v1/admin/trips`,
    edit: id => `${API_BASE_URL}/api/v1/admin/trips/${id}`,
    delete: id => `${API_BASE_URL}/api/v1/admin/trips/${id}`
  },
  CASHOUT_REQUEST: {
    list: `${API_BASE_URL}/api/v1/admin/withdraw/requests`,
    edit: id => `${API_BASE_URL}/api/v1/admin/withdraw/requests/${id}`
  },
  COMPLAINTS: {
    list: `${API_BASE_URL}/api/v1/admin/complaints`
  }
}
